import { CwtApiSchema, CwtModel, CwtProp, CwtSchema } from "@cawita/core-front/api";
import { EvaluationResultEntity } from "./eval-result.entity";

@CwtSchema()
export class EvalMentalEye extends CwtApiSchema {
    @CwtProp() complete: Number;
    @CwtProp() middle: Number;
    @CwtProp() end: Number;
}


@CwtSchema()
export class EvalMentalResult extends CwtApiSchema {
    @CwtProp() vpeak: Number;
    @CwtProp() vmax: Number;
    @CwtProp({ ref: EvalMentalEye}) leftEye: EvalMentalEye;
    @CwtProp({ ref: EvalMentalEye}) rightEye: EvalMentalEye;
}

@CwtModel({ name: 'eval-mental' })
export class EvalMental extends EvaluationResultEntity {
    @CwtProp({ ref: EvalMentalResult }) result: EvalMentalResult;
}