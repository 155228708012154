import { CwtApiSchema, CwtModel, CwtProp, CwtSchema } from "@cawita/core-front/api";
import { EvaluationResultEntity } from "./eval-result.entity";

export type PainLevel = 'low' | 'medium' | 'high';
export type PainSize = 'small' | 'medium' | 'large';

export type PainPointConfig = {
    level: PainLevel; 
    size: PainSize;
}

@CwtSchema()
export class PainPoint extends CwtApiSchema {
    @CwtProp() x: number;
    @CwtProp() y: number;
    @CwtProp() level: PainLevel;
    @CwtProp() size: PainSize;
}

@CwtSchema()
export class EvalPhysicalResult extends CwtApiSchema {
    @CwtProp() isPhysicallyActive: boolean;
    @CwtProp() isPracticingSports: boolean;
    @CwtProp() practicedSports: string[];
    @CwtProp() activityDuration: string;
    @CwtProp() practiceFrequency: string;
    @CwtProp() practiceDuration: string;
    @CwtProp() wellness: number;
    @CwtProp({ ref: PainPoint }) frontPainPoints: PainPoint[];
    @CwtProp({ ref: PainPoint }) backPainPoints: PainPoint[];
}

@CwtModel({ name: 'eval-physical' })
export class EvalPhysical extends EvaluationResultEntity {
    @CwtProp() painScore: number;
    @CwtProp() activityScore: number;
    @CwtProp() wellnessScore: number;
    @CwtProp() physicalActivityScore: number;
    @CwtProp() sportPracticeScore: number;
    @CwtProp({ ref: EvalPhysicalResult }) result: EvalPhysicalResult;
}