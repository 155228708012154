import { CwtApiSchema, CwtModel, CwtProp, CwtSchema } from "@cawita/core-front/api";
import { EvaluationResultEntity } from "./eval-result.entity";

@CwtSchema()
export class EvalEnergeticalResult extends CwtApiSchema {
    @CwtProp() heartrate: number;
    @CwtProp() rmssd: number;
}

@CwtModel({ name: 'eval-energetical' })
export class EvalEnergetical extends EvaluationResultEntity {
    @CwtProp() rmssdScore: number;
    @CwtProp() wellnessScore: number;
    @CwtProp() physicalActivityScore: number;
    @CwtProp() sportPracticeScore: number;
    @CwtProp({ ref: EvalEnergeticalResult }) result: EvalEnergeticalResult;
}