'use strict';

const { isset, findByAddress } = require('@hints/utils/data');
const { sum, clamp, ratio } = require('@hints/utils/math');

const evalTypesToField = {
    sleep: 'evalSleep',
    physical: 'evalPhysical',
    emotional: 'evalEmotional',
    mental: 'evalMental',
    energetical: 'evalEnergetical',
    cerebral: 'evalCerebral'
};

const evalTypesWithRecos = ['sleep', 'physical', 'emotional', 'mental', 'energetical', 'cerebral'];

const levelRanges = [
    { level: 'maintain', min: 80, max: 100 },
    { level: 'optimize', min: 60, max: 80 },
    { level: 'monitor', min: 40, max: 60 },
    { level: 'act', min: 20, max: 40 },
    { level: 'react', min: 0, max: 20 },
];

function _getEvalFieldFromType(type) { return evalTypesToField[type]; }

function _getLevelForScore(score) {
    score = clamp(score, 0, 100);
    const levelObj = levelRanges.find(level => level.min <= score && level.max >= score);
    if (!isset(levelObj)) return 'react';
    return levelObj.level;
}

function _getPerTypeRecommandations(evaluation) {
    const typesWithResults = evalTypesWithRecos.filter(t => isset(findByAddress(evaluation, `${_getEvalFieldFromType(t)}.score`)));
    const types = typesWithResults.map(type => {
        const field = _getEvalFieldFromType(type);
        const score = evaluation[field].score || 0;
        return ({
            type: type,
            score: score,
            level: _getLevelForScore(score)
        });
    });
    return types.sort((a, b) => a.score - b.score);
}

function _getRecoTypeAndLevel(evaluation, perType) {
    if(!perType.length) return { type: null, level: null };
    const { type, level } = perType[0];
    if(type === 'physical') {
        const activityScore = findByAddress(evaluation, 'evalPhysical.activityScore') || 0;
        const painScore = findByAddress(evaluation, 'evalPhysical.painScore') || 0;
        if (painScore > activityScore) return { type: 'pain', level };
        else return { type: 'physical', level };
    }

    return { type, level };
}

function getRecommendation(evaluation) {
    const perType = _getPerTypeRecommandations(evaluation);
    const totalScore = sum(perType.map(r => r.score));
    const averageScore = clamp(ratio(totalScore, perType.length), 0, 100);
    const averageLevel = _getLevelForScore(averageScore);
    const { level, type } = _getRecoTypeAndLevel(evaluation, perType);
    return {
        averageScore: averageScore,
        averageLevel: averageLevel,
        categoryType: type,
        categoryLevel: level,
        perType: perType,
    };
}

module.exports = { getRecommendation };